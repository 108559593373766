import React, { useEffect, useState } from "react";
import "../styles/App.scss";
import Modal from "react-modal";
import Page from "../layouts/Page";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const [cookies, setCookies] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  function goToHome(e) {
    window.location.href = "#";
    e.preventDefault();
    e.stopPropagation();
  }

  function goToProjects(e) {
    window.location.href = "#projects";
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <Page
      content={
        <div>
          <section className="min-h-screen">
            <div className="bgg py-20">
              <div className="md:max-w-7xl mx-auto flex flex-col md:flex-row items-center">
                <div className="w-1/2">
                  <h1 className="uppercase font-bold px-2 text-xl md:text-4xl text-white">Contact</h1>
                </div>
              </div>
            </div>
            <form className="w-full px-2 md:w-4/12 mx-auto bg-white rounded-xl p-5">
              <div className="flex flex-col">
                <div className="flex items-center gap-5">
                  <input
                    className="w-1/2 text-sm py-3 pl-5 pr-2 rounded bg-gray-100"
                    name="last_name"
                    type="text"
                    placeholder="Nom"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <input
                    className="w-1/2 text-sm py-3 pl-5 pr-2 rounded bg-gray-100 my-3"
                    name="first_name"
                    type="text"
                    placeholder="Prénom"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>

                <input
                  className="text-sm py-3 pl-5 pr-2 rounded bg-gray-100"
                  name="email"
                  type="e-mail"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <input
                  className="text-sm py-3 pl-5 pr-2 rounded bg-gray-100 mt-3 min-h-[15vh]"
                  name="message"
                  type="text"
                  placeholder="Votre message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    // login();
                  }}
                  className="py-3 px-5 text-sm text-white bg-red-500 hover:bg-red-600 rounded mt-10"
                >
                  Envoyer le message
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="mt-10 px-5 text-xs text-center font-light hover:underline text-gray-400"
                >
                  Je consens à ce que mes informations soient traitées de
                  manière éphémère de façon à traiter ma demande de prise de
                  contact
                </button>
              </div>
            </form>
          </section>
        </div>
      }
    />
  );
}
