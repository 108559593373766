import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const NavLink = ({ text, icon, link }) => (
    <a
      onClick={(e) => {
        e.preventDefault();
        navigate(link);
      }}
      href="#"
      className="hover:underline px-2 rounded-full flex flex-row items-center"
    >
      {/* <i className={icon}></i> */}
      <span className="">{text}</span>
    </a>
  );

  const NavIcon = ({ link, icon }) => (
    <a href={link} target="_blank" className="mx-3">
      <i className={icon}></i>
    </a>
  );

  return (
    <footer className="w-full">
      <div className="bg-gray-100 py-7 border-t border-gray-200">
        <div className="max-w-7xl p-3 text-gray-500 mx-auto flex flex-col md:flex-row justify-between">
          <div className="flex items-center">
            <img
              className={isMobile ? "w-10 h-10" : "w-14 h-14"}
              src={require("../img/logo.png")}
            />
            {/* <a className="ml-2 font-regular">Loka</a> */}
          </div>

          <div className="flex flex-col my-10 md:my-0">
            <ul className="text-md font-regular">
              <li>
                <a
                  className="cursor-pointer hover:underline"
                  onClick={() => navigate("/")}
                >
                  Annonces récentes
                </a>
              </li>
              {/* <li>
                <a
                  className="cursor-pointer hover:underline"
                  onClick={() => navigate("/about")}
                >
                  A propos de nous
                </a>
              </li>
              <li>
                <a
                  className="cursor-pointer hover:underline"
                  onClick={() => navigate("/contact")}
                >
                  Nous contacter
                </a>
              </li> */}
            </ul>
          </div>

          <div className="flex flex-col mb-10 md:mb-0">
            <ul className="text-md font-regular">
              <li>
                <a
                  className="cursor-pointer hover:underline"
                  onClick={() => navigate("/terms")}
                >
                  Conditions d'utilisation
                </a>
              </li>
              <li>
                <a
                  className="cursor-pointer hover:underline"
                  onClick={() => navigate("/policy")}
                >
                  Politique de confidentialité
                </a>
              </li>
            </ul>
          </div>

          <div>
            <a
              onClick={() => navigate("/downloadLink")}
              className="font-semibold primaryButton"
            >
              Télecharger l'application
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
