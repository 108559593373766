import React, { useEffect, useState } from "react";
import "../styles/App.scss";
import Modal from "react-modal";
import Page from "../layouts/Page";
import { useNavigate } from "react-router-dom";

export default function Policy() {
  const navigate = useNavigate();

  const TermCard = ({ title, text }) => (
    <div className="my-5">
      <h2 className="font-semibold text-black mb-3">{title}</h2>
      <p className="text-sm leading- text-gray-800 font-light">{text}</p>
    </div>
  );

  return (
    <Page
      content={
        <div>
          <section className="min-h-screen">
            <div className="bgg py-20">
              <div className="md:max-w-7xl mx-auto flex flex-col">
                <div className="w-full md:w-1/2">
                  <h1 className="uppercase font-bold px-2 text-xl md:text-3xl text-white">
                    Politique de confidentialité
                  </h1>
                </div>
              </div>
            </div>
            <div className="px-2 w-full md:w-7/12 mx-auto bg-white rounded-xl p-5 break-words">
              <TermCard
                title={`1. INTRODUCTION`}
                text={`Bienvenue sur Loka, l'application de location immobilière et automobile. Chez Loka, nous accordons une grande importance à la protection de vos données personnelles. La présente politique de confidentialité vise à vous informer sur la manière dont nous collectons, utilisons, stockons et partageons vos données personnelles. En utilisant l'application Loka, vous acceptez cette politique de confidentialité.`}
              />
              <TermCard
                title={`2. Données Collectées`}
                text={``}
              />
              <TermCard
                title={`2-1. Informations d'Inscription `}
                text={`Lors de votre inscription sur Loka, nous collectons les numéros de téléphone, le nom et le prénom pour créer votre profil d'utilisateur`}
              />
              <TermCard
                title={`2-2. Informations de Paiement `}
                text={`Lorsque vous effectuez des paiements via Loka, des informations de paiement, telles que des détails de carte de crédit, peuvent être collectées via des services de paiement tiers sécurisés. Nous ne stockons pas ces informations de paiement localement.`}
              />
              <TermCard
                title={`3. Utilisation des Données`}
                text={``}
              />
              <TermCard
                title={`3-1 Utilisation des Données`}
                text={`Nous utilisons vos données personnelles pour vous offrir des services de location immobilière et automobile, y compris la recherche de biens, les réservations et les paiements.`}
              />
              <TermCard
                title={`3-2. Amélioration de l'Application`}
                text={`Nous pouvons utiliser des données agrégées et anonymisées pour améliorer notre application, nos services et notre expérience utilisateur`}
              />
              <TermCard
                title={`4. Stockage des Données`}
                text={`Vos données personnelles sont stockées de manière sécurisée sur des serveurs gérés par Firebase, notre prestataire de services cloud. Nous prenons des mesures pour protéger vos données contre tout accès non autorisé.`}
              />
              <TermCard
                title={`5. Partage des Données`}
                text={`Nous ne partageons pas vos données personnelles avec des tiers, sauf si cela est nécessaire pour fournir nos services, répondre à des obligations légales ou pour protéger nos droits.`}
              />
              <TermCard title={`6. Sécurité des Données`} text={`Nous mettons en place des mesures de sécurité techniques et organisationnelles pour protéger vos données personnelles contre les accès non autorisés, la perte ou la divulgation`} />
              <TermCard title={`7. Droits des Utilisateurs`} text={`Vous avez le droit d'accéder à vos données personnelles, de les rectifier, de les supprimer et de vous opposer à leur traitement. Pour exercer ces droits, veuillez nous contacter à noreply@my-loka.com.`} />
              <TermCard title={`8. Consentement et Modifications`} text={`En utilisant Loka, vous consentez à cette politique de confidentialité. Nous nous réservons le droit de la modifier à tout moment, et les modifications seront affichées dans l'application. Votre utilisation continue de Loka après de telles modifications constitue votre acceptation de la politique de confidentialité révisée.`} />
              <TermCard title={`9. Contact`} text={`Pour toute question ou préoccupation concernant notre politique de confidentialité, veuillez nous contacter à noreply@my-loka.com`} />
             
            </div>
          </section>
        </div>
      }
    />
  );
}
