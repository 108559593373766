import React, { useEffect, useState } from "react";
import "../styles/App.scss";
import Modal from "react-modal";
import Page from "../layouts/Page";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

export default function Download() {
  const [countValues, setCountValues] = useState(null);
  const appStoreURL = "https://apps.apple.com/us/app/myloka/id6475269634";
  const playStoreURL =
    "https://play.google.com/store/apps/details?id=com.sunder.myloka";

  const navigate = useNavigate();

  useEffect(() => {
    getValue();
  }, []);

  const getValue = async () => {
    const docRef = doc(db, "surveys", "downloadPageClicks");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCountValues(docSnap.data());
    }
  };
  const incrementAndroidClick = async () => {
    await updateDoc(doc(db, "surveys", "downloadPageClicks"), {
      android: countValues.android + 1,
    });
    getValue();
  };
  const incrementIOSClick = async () => {
    await updateDoc(doc(db, "surveys", "downloadPageClicks"), {
      ios: countValues.ios + 1,
    });
    getValue();
  };

  return (
    <Page
      content={
        <section className="flex flex-col items-center justify-center min-h-screen bgLinear">
          <div className="-mt-[10%] w-full p-3 md:w-5/12 mx-auto rounded-xl">
            <h1 className="text-white text-xl md:text-2xl font-bold">
              Découvrez MyLoka
            </h1>
            <h1 className="mt-5 text-gray-100 text-base md:text-lg font-regular">
              La meilleure façon de trouver et de louer des biens immobiliers et
              bientôt des véhicules en un clin d'œil ! 🏠🚗 Rejoignez la
              révolution de la location sur MyLoka. Téléchargez l'application
              maintenant et économisez sur chaque location.
            </h1>
            <div className="mt-5 flex items-center">
              <a
                onClick={incrementIOSClick}
                target="_blank"
                href={appStoreURL}
                className="w-40 p-2 -ml-2"
              >
                <img className="w-full" src={require("../img/appStore.png")} />
              </a>
              <a
                onClick={incrementAndroidClick}
                target="_blank"
                href={playStoreURL}
                className="w-40"
              >
                <img className="w-full" src={require("../img/playStore.png")} />
              </a>
            </div>
          </div>
        </section>
      }
    />
  );
}
