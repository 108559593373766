import React from "react";
import { useNavigate } from "react-router-dom";

export default function Trips() {
  let navigate = useNavigate();

  React.useEffect(() => {
    window.location.replace("myloka://trips");
  }, []);

  return <div>Redirection...</div>;
}
