import React, { useEffect, useState } from "react";
import "../styles/App.scss";
import Modal from "react-modal";
import Page from "../layouts/Page";
import { useNavigate } from "react-router-dom";

export default function Terms() {
  const navigate = useNavigate();

  const TermCard = ({ title, text }) => (
    <div className="my-5">
      <h2 className="font-semibold text-black mb-3">{title}</h2>
      <p className="text-sm leading- text-gray-800 font-light">{text}</p>
    </div>
  );

  return (
    <Page
      content={
        <div>
          <section className="min-h-screen">
            <div className="bgg py-20">
              <div className="md:max-w-7xl mx-auto flex flex-col">
                <div className="w-full md:w-1/2">
                  <h1 className="uppercase font-bold px-2 text-xl md:text-3xl text-white">
                    Termes & conditions générales
                  </h1>
                </div>
              </div>
            </div>
            <div className="px-2 w-full md:w-7/12 mx-auto bg-white rounded-xl p-5 break-words">
              <TermCard
                title={`1. INTRODUCTION`}
                text={`Les présentes Conditions Générales d'Utilisation (ci-après "CGU") régissent l'utilisation de l'application Loka (ci-après "Loka") pour la location immobilière et automobile. En utilisant Loka, vous acceptez de vous conformer aux CGU énoncées ci-dessous.`}
              />
              <TermCard
                title={`3. Inscription`}
                text={`Lors de votre inscription sur Loka, nous collectons les numéros de téléphone, le nom et le prénom. Vos mots de passe ne sont pas stockés localement, mais sont gérés par Firebase.`}
              />
              <TermCard
                title={`4. Utilisation de l'Application`}
                text={`Loka vous permet de rechercher, louer et gérer des biens immobiliers et des véhicules. Vous acceptez d'utiliser l'application conformément à toutes les lois applicables.`}
              />
              <TermCard
                title={`5. Protection des Données`}
                text={`Nous nous engageons à protéger vos données conformément à notre Politique de Confidentialité. Vos informations personnelles sont stockées et traitées en toute sécurité.`}
              />
              <TermCard
                title={`6. Paiements`}
                text={`Loka gère les paiements via des services sécurisés. Vous acceptez de fournir des informations de paiement valides et de vous conformer aux modalités de paiement spécifiques de Loka.`}
              />
              <TermCard
                title={`7. Respect de la Loi`}
                text={`Vous acceptez de vous conformer à toutes les lois et réglementations applicables lors de l'utilisation de Loka.`}
              />
              <TermCard
                title={`8. Résiliation`}
                text={`Vous pouvez résilier votre compte à tout moment conformément aux procédures de résiliation fournies par Loka. Loka se réserve le droit de résilier ou suspendre votre compte en cas de violation des CGU.`}
              />
              <TermCard
                title={`9. Limitation de Responsabilité`}
                text={`Loka ne peut être tenu responsable des dommages directs, indirects ou consécutifs résultant de l'utilisation de l'application. Vous utilisez Loka à vos propres risques.`}
              />
              <TermCard
                title={`9. Contact`}
                text={`Pour toute question ou préoccupation concernant notre politique de confidentialité, veuillez nous contacter à noreply@my-loka.com`}
              />
              <TermCard
                title={`10. Modifications des CGU`}
                text={`Loka se réserve le droit de modifier les CGU à tout moment. Les modifications seront affichées sur l'application. Votre utilisation continue de Loka après de telles modifications constitue votre acceptation des CGU révisées.`}
              />
              <TermCard
                title={`11. Contact`}
                text={`Pour toute question ou préoccupation concernant les CGU, veuillez nous contacter à noreply@my-loka.com`}
              />
            </div>
          </section>
        </div>
      }
    />
  );
}
