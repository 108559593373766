import React from "react";
import { useNavigate,useParams  } from "react-router-dom";

export default function Details() {
  let navigate = useNavigate();
  let { id } = useParams();

  React.useEffect(() => {
    window.location.replace(`myloka://details/${id}`);
  }, []);

  return <div>Redirection...</div>;
}
