import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import moment from "moment";
import "moment/locale/fr";

export default function Home() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const [allListings, setAllListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    moment.locale("fr");
    getListings();
  }, [isMobile]);

  const getListings = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "listings"),
          where("isActive", "==", true),
          orderBy("createdAt", "desc"),
          limit(20)
        )
      );

      const dataArr = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAllListings(dataArr);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (input) => {
    let numbers = input.toString().replace(/\s/g, "");

    let formatted = "";
    for (let i = numbers.length - 1, j = 1; i >= 0; i--, j++) {
      formatted = numbers[i] + formatted;
      if (j % 3 === 0 && i !== 0) {
        formatted = " " + formatted;
      }
    }

    return formatted;
  };

  const ListingCard = ({ item }) => (
    <div className="w-[90vw] md:w-[20vw] mb-2 relative">
      {item?.premium?.isPremium && (
        <div
          style={{ backgroundColor: "#F97316" }}
          className="absolute -right-1 -top-1 px-2 py-1 rounded-md"
        >
          <span className="text-sm font-regular text-white">PRO</span>
        </div>
      )}
      <a href={`myloka://details/${item.id}`}>
        <img
          className="rounded-xl bg-gray-100 w-full h-[25vh] md:h-[25vh] object-cover shadow"
          src={item.images[0]}
        />
      </a>
      <div className="p-2">
        <h3 className="text-sm md:md:text-base font-regular">
          {formatPrice(item.price)} CFA/nuit
        </h3>
        <div className="flex flex-col">
          {/* <div
            style={{ height: 5, width: 5 }}
            className="mr-1 rounded-full bg-green-500"
          ></div> */}
          <h4 className="text-xs text-gray-500  font-light">
            {item.piece.bedroom + item.piece.livingroom} pièces
            <a
              // href={`myloka://search/${item.address.commune.toLowerCase()}`}
              className="capitalize cursor-pointer text-blue-500"
            >
              {" "}
              • {item.address.commune}
            </a>
          </h4>
          <h5 className="text-xs text-gray-400 font-light">
            {moment(item.createdAt).fromNow()}
          </h5>
        </div>
      </div>
    </div>
  );

  const LandingBG = () => (
    <div className="bgg py-40 flex-1 justify-center items-center">
      <div className="px-4 max-w-xl md:max-w-7xl mx-auto">
        <h1 className="text-white text-2xl md:text-5xl font-bold">
          Votre coin de paradis
        </h1>
        <h2 className="text-gray-200 text-lg md:text-2xl font-regular">
          Juste pour un instant
        </h2>
      </div>
    </div>
  );

  const Listings = () => (
    <div className="px-3 max-w-7xl py-5 md:py-10 mx-auto">
      <div className="flex flex-row items-center justify-between mb-5">
        <h2 className="text-xl font-regular">Annonces récentes</h2>
        <div className="flex flex-row items-center justify-center">
          <a href="myloka://" className="text-sm font-light mr-2">
            Voir tout
          </a>
          <i class="fa-solid fa-chevron-right text-gray-400 text-xs"></i>
        </div>
      </div>
      <div className="flex flex-row flex-wrap items-center justify-center gap-3">
        {allListings.map((item, index) => (
          <div key={index}>
            <ListingCard item={item} />
          </div>
        ))}
      </div>
    </div>
  );

  if (isLoading) return null;

  return (
    <div className="flex-1 bg-white">
      <Header />
      {/* <LandingBG /> */}
      <Listings />
      <Footer />
    </div>
  );
}
