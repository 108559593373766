import React from "react";
import { useNavigate } from "react-router-dom";

export default function HostTrips() {
  let navigate = useNavigate();

  React.useEffect(() => {
    window.location.replace(`myloka://hostTrips`);
    
  }, []);

  return <div>Redirection...</div>;
}
