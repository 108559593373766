import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import Download from "./pages/Download";
import Trips from "./pages/Trips";
import HostTrips from "./pages/HostTrips";
import Details from "./pages/Details";

function App() {
  return (
    <Routes>
      <>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/share/:id" element={<Details />} />
        <Route path="/trips" element={<Trips />} />
        <Route path="/hostTrips" element={<HostTrips />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/downloadLink" element={<Download />} />
      </>
    </Routes>
  );
}

export default App;
