import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../config/firebase";
import { signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { useMediaQuery } from "react-responsive";

// Modal.setAppElement(Header);

export default function Header() {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const customStyles = {
    content: {
      width: isMobile ? "80%" : "40%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    const unsub = onSnapshot(doc(db, "users", auth.currentUser.uid), (doc) => {
      let data = doc.data();
      setUserInfo(data);
    });
    return () => unsub();
  };

  const LoginModal = () => (
    <Modal isOpen={loginModalIsOpen} style={customStyles}>
      <div className="relative w-5/6">
        <button
          className="bg-black text-white p-3 absolute right-5 top-5"
          onClick={() => setLoginModalIsOpen(false)}
        >
          X
        </button>
        <h3 className="font-bold text-2xl">Connexion</h3>
      </div>
    </Modal>
  );

  const logOut = () => {
    signOut(auth);
    navigate("/");
  };

  const NavLink = ({ text, icon, link, onClick, logout }) =>
    !logout ? (
      <a
        onClick={(e) => {
          e.preventDefault();
          navigate(link);
        }}
        className="cursor-pointer text-sm hover:bg-gray-100 px-5 py-3 rounded-full flex flex-row items-center mx-1"
      >
        {/* <i className={icon}></i> */}
        <span className="">{text}</span>
      </a>
    ) : (
      <button
        onClick={onClick}
        className="text-sm hover:bg-gray-100 px-5 py-3 rounded-full flex flex-row items-center mx-1"
      >
        {/* <i className={icon}></i> */}
        <span className="">{text}</span>
      </button>
    );

  const NavIcon = ({ link, icon }) => (
    <a
      href={link}
      target="_blank"
      className="mx-2 md:mx-3 bg-white w-6 h-6 rounded-full flex items-center justify-center"
    >
      <i className={icon}></i>
    </a>
  );

  const MiniNavBar = () => (
    <div className="text-white w-full flex items-end justify-end bg-black p-2 md:p-5">
      <NavIcon
        link="https://www.facebook.com/profile.php?id=100090908024542"
        icon={"fa-brands fa-facebook text-blue-800"}
      />
      <NavIcon
        link="https://twitter.com/bonsplanscourse?t=dpcEoEUP-JnPUFCCeF1xBg&s=09"
        icon={"fa-brands fa-twitter text-blue-300"}
      />
      <NavIcon link="#" icon={"fa-brands fa-youtube text-red-500"} />
      <NavIcon
        link="https://www.tiktok.com/@mesbonsplanscourses"
        icon={"fa-brands fa-tiktok text-black"}
      />
    </div>
  );

  const NavBar = () => (
    <nav className="transition ease-in-out duration-200 bg-white w-[100vw] p-2 md:p-5 absolute top-24 md:top-0  md:right-0 md:relative">
      <div className="flex flex-col md:flex-row md:items-end md:justify-end">
        <NavLink link={"/"} text="Accueil" icon={"bi bi-whatsapp"} />
        <NavLink
          link={"/contact"}
          text="Nous contacter"
          icon={"bi bi-whatsapp"}
        />
        <NavLink
          link={"/terms"}
          text="Conditions d'utilisation"
          icon={"bi bi-whatsapp"}
        />
        {auth.currentUser && (
          <NavLink
            logout
            onClick={(e) => {
              e.preventDefault();
              logOut();
            }}
            text="Se déconnecter"
            icon={"bi bi-whatsapp"}
          />
        )}

        <button
          onClick={(e) => {
            e.preventDefault();
            if (auth.currentUser) {
              return;
            } else {
              navigate("/login");
            }
          }}
          className="w-11/12 md:w-auto self-center capitalize mt-5 md:mt-0 md:ml-10 font-bold text-sm rounded-full px-5 py-2 md:py-3 bg-red-500 text-white shadow"
        >
          {auth.currentUser
            ? `Bienvenue, ${userInfo?.firstName}`
            : "Connexion | Inscription"}
        </button>
      </div>
    </nav>
  );

  return (
    <>
      {/* <LoginModal /> */}
      <header className="w-full bg-white">
        <div className="bg-white  border-b border-gray-200">
          <div className="max-w-7xl p-3 mx-auto flex items-center justify-between">
            <a
              onClick={() => navigate("/")}
              className="cursor-pointer flex items-center"
            >
              <img
                className={isMobile ? "w-10 h-10" : "w-14 h-14"}
                src={require("../img/logo.png")}
              />
              {/* <a className="ml-2 font-regular">Loka</a> */}
            </a>
            {!isMobile ? (
              <div className="flex items-center">
                <div className="text-sm font-regular">
                  {/* <a className="p-2 cursor-pointer hover:underline">Annonces</a> */}
                  {/* <a className="p-2 cursor-pointer hover:underline">A propos de nous</a> */}
                  {/* <a className="p-2 cursor-pointer hover:underline">Contact</a> */}
                </div>
                <div>
                  <a
                    onClick={() => navigate("/downloadLink")}
                    className="text-sm ml-10 font-semibold primaryButton"
                  >
                    Télecharger l'application
                  </a>
                </div>
              </div>
            ) : (
              // <div className="h-10 w-10 text-xl flex justify-center items-center rounded-full bg-gray-100">
              //   <i class="fa-solid fa-bars "></i>
              // </div>
              <div>
              <a
                onClick={() => navigate("/downloadLink")}
                className="text-sm ml-10 font-semibold mobilePrimaryButton"
              >
                Télecharger l'application
              </a>
            </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
}
